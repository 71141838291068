<template>
    <div class="QRCodeModeResources" style="background: #d1e0f9;padding-top: 50px;padding-bottom: 50px;">
        <el-row >
            <el-col :span="24" style="background: #fff;padding: 15px;">
                <el-col :span="24">
                    <el-col class="" :span="24" style="margin-bottom: 20px;">
                        <!--<el-radio-group v-model="labelPosition" size="small">-->
                            <!--&lt;!&ndash;<el-radio-button label="2D" v-show="img_show">2D</el-radio-button>&ndash;&gt;-->
                            <!--<el-radio-button label="shipin" v-show="video_show">视频</el-radio-button>-->
                            <!--<el-radio-button label="3D" v-show="show">3D</el-radio-button>-->
                        <!--</el-radio-group>-->

                    </el-col>
                    <el-col class="" :span="24" style="margin-bottom: 30px;">
                        <el-col style="">
                            <!--<img v-show="img1_show" style="width: 100%" :src="common.retun_img(parameter.file_image)" >-->

                            <video id="needvideo" v-show="video1_show" style="width: 100%;height: 100%;" :poster=common.retun_img(parameter.thumbnail)
                             :src=common.retun_ossimg(parameter.file_mp4) controlslist="nodownload" controls="" preload="auto" oncontextmenu="return false"></video>
                            <!-- 定义DOM元素，用于在该DOM元素中显示模型或图纸 -->
                            <div id="domId" v-show="show1"></div>


                        </el-col>
                    </el-col>
                    <el-col class="" :span="24" style="border-top:5px solid #d1e0f9;padding-top: 20px;padding-bottom: 20px;">
                        <el-col style="">
                            <p style="font-size: 16px;line-height: 40px;">概述：</p>
                            <span class="rabbit-font">{{parameter.introduce}}</span>
                        </el-col>
                    </el-col>
                    <el-col class="" :span="24" style="border-top:5px solid #d1e0f9;padding-top: 30px;margin-bottom: 20px;line-height: 40px;">
                        <el-col style="" :span="24">版权说明：</el-col>
                        <el-col style="" :span="24">在本站购买的素材资源只拥有使用权，著作权归作者及BIM秀秀网所有。</el-col>
                        <el-col style="" :span="24">未经合法授权，会员不得以任何形式发布、传播、复制、转售该资源。</el-col>
                    </el-col>
                </el-col>
            </el-col>

        </el-row>

<!--<login  v-show="showlogin" @closeDialog="closeDialog"></login>-->
    </div>
</template>
<script>
    import {
        requestDetailstow,
        requestAddpush,
		requestaccount_collect_add,
		requestdirectory_list,
        requestResource_purchase,
		requestdirectory_add,
		get_scan_resource
    } from "../api/request";
    import BimFace from '../assets/js/BimfaceSDKLoader@latest-release.js'
    export default {
        name: "BimFace",
        components: {
            BimFace,
        },
        data () {
            return {
                radio: '1',
                parameter:{},
                labelPosition:'2D',
                show:true,
                video_show:true,
                img_show:true,
                show1:true,
                video1_show:true,
                img1_show:true,
                viewer3D:'',
                app:'',
                loaderConfig:null,
                viewer:null,
                is_click:0,
                BimfaceLoaderConfig:new BimfaceSDKLoaderConfig(),
				//select选择框
				options: [],
                is_zigm:true,
				imgurl:require('../assets/img/rotate.png'),
            };
        },
        methods:{
            //显示3D资源
            onSDKLoadSucceeded(viewMetaData){
				var modelstate = "";
				if (viewMetaData.viewType == "drawingView") {
					var view = document.getElementById('domId');
					var WebAppConfig = new Glodon.Bimface.Application.WebApplicationDrawingConfig();
					WebAppConfig.domElement = view;
					var app = new Glodon.Bimface.Application.WebApplicationDrawing(WebAppConfig);
					viewer = app.getViewer();
					viewer.addModel(viewMetaData);
					modelstate = "2D";

				} else if (viewMetaData.viewType == "3DView") {
					var view = document.getElementById('domId')
					var config = new Glodon.Bimface.Application.WebApplication3DConfig();
					config.domElement = view;
					var eventManager = Glodon.Bimface.Application.WebApplication3DEvent;
					this.app = new Glodon.Bimface.Application.WebApplication3D(config);
					this.viewer = this.app.getViewer();
					this.viewer.addModel(viewMetaData);
					modelstate = "3D";

				} else if (viewMetaData.viewType == "rfaView") {
					var view = document.getElementById('domId')
					//var config = new Glodon.Bimface.Application.WebApplicationRfaConfig();
					var config = new Glodon.Bimface.Application.WebApplication3DConfig();
					config.domElement = view;
					var eventManager = Glodon.Bimface.Application.WebApplicationRfaEvent;
					app = new Glodon.Bimface.Application.WebApplicationRfa(config);
					this.viewer = this.app.getViewer();
					viewer.addModel(viewMetaData);
					modelstate = "3D";
				}
				let _this = this;
				this.viewer.addEventListener(Glodon.Bimface.Viewer.Viewer3DEvent.ViewAdded, function() {

					if (modelstate == "3D") {
						_this.viewer.startAutoRotate(-2);
						_this.viewer.setExposureShift(-0.1);
						_this.viewer.setBorderLineEnabled(false);

                        btnRotate("自动旋转",1);
                        function btnRotate(title,inde){
                            var toolbar = _this.app.getToolbar('MainToolbar');
                            var btnConfig = new Glodon.Bimface.UI.Button.ButtonConfig();
                            btnConfig.title = title;

                            var btn = new Glodon.Bimface.UI.Button.Button(btnConfig);
								console.log(btn)
                            //btn.setHtml("<i class='fa fa-rotate-right' onclick='onrotate();' style='font-size: 24px;'></i>");
                            btn.setHtml("<img src='"+_this.imgurl+"'  style='width: 24px;' alt=''>");//<i class='fa fa-rotate-right' onclick='onrotate();' style='font-size: 24px;'></i>
                            btn.addClassName("rotate");
							btn.element.onclick=()=>{
								_this.onrotate()
							}
                            toolbar.insertControl(inde, btn);
                        }
					}

				});
			},
            //错误显示
            onSDKLoadFailed(error){
                console.log("Failed to load SDK!");
            },
			onrotate() {
			    this.rotate = !this.rotate;
			    if(this.rotate){
			        this.viewer.startAutoRotate(-2);
			    }else {
			       this.viewer.startAutoRotate(0);
			    }
			},
            //是否显示某类型
            Details:function () {
                    let id=window.atob(this.common.GetRequest().id),_this=this;
                    let data={
                        id:parseInt(id)
                    };
                    requestDetailstow(data).then(function(e) {
						//请求模型数据
                        _this.parameter=e.ret;
                        if(e.ret.offlinepackurl==null){
                            _this.show=false
                        }else {
                            _this.show1=true
                            _this.BimfaceLoaderConfig.dataEnvType = BimfaceEnvOption.Local;
                            _this.BimfaceLoaderConfig.sdkPath = _this.parameter.jssdk+'/jssdk';
                            _this.BimfaceLoaderConfig.path = _this.parameter.offlinepackurl+'/viewToken.json';
                            BimfaceSDKLoader.load(_this.BimfaceLoaderConfig,_this.onSDKLoadSucceeded,_this.onSDKLoadFailed);
                        }
                        if(e.ret.file_mp4==null || e.ret.file_mp4==''){
                            _this.video_show=false
                            _this.video1_show=false
                        }else {
                            _this.video1_show=true
                        }

                    });
                },
            //加购
            Shopping(e){
                let _this=this;
                requestAddpush(e).then(function (res) {
                    if(res.code==0){
                        _this.$message({
                            message: '加入购物车成功',
                            type: 'success'
                        });
                    }
                })
            },
			onBridgeReady: function() {
				WeixinJSBridge.call('hideOptionMenu');
			},

            download(){
                this.dialogVisible_download = true
            },
        },
         watch: {
                labelPosition:function(newVal,oldVal){
                    let _this=this;
                //单选监听实时获取
                    if(newVal=="2D"){
                        this.show1=false;
                        this.video1_show=false;
                        this.img1_show=true
                    }else if(newVal=="3D"){
                        this.show1=true;
                        this.video1_show=false;
                        this.img1_show=false;
                        if(this.is_click==0){
                        _this.BimfaceLoaderConfig.dataEnvType = BimfaceEnvOption.Local;
                        _this.BimfaceLoaderConfig.sdkPath = _this.parameter.jssdk+'/jssdk';
                        _this.BimfaceLoaderConfig.path = _this.parameter.offlinepackurl+'/viewToken.json';
                        BimfaceSDKLoader.load(_this.BimfaceLoaderConfig,_this.onSDKLoadSucceeded,_this.onSDKLoadFailed);
                        }
                        this.is_click=1
                    }else if(newVal=='shipin'){
                        this.show1=false;
                        this.video1_show=true;
                        this.img1_show=false;
                    }
            },

        },
        mounted(){
            this.Details();
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = 'https://res.bimshows.cn/bimface/jssdk/BimfaceSDKLoader@latest-release.js';
            document.body.appendChild(s);
			
			let _this=this;
			console.log(this.$parent)
			this.$parent.$refs.Rightfloatingframe.$el.style.display = 'none';
			this.$parent.$refs.headerTop.$el.style.display = 'none';
			this.$parent.$refs.login.$el.style.display = 'none';
			this.$parent.$refs.navBar.$el.style.display = 'none';
			// this.$parent.$children[4].$el.style.display = 'none';
        },
    };

</script>
<style scoped>
    @import "../style/common.css";
    .shaixuan{
        background: #fff;
    }
    .shaixuan>div{
        height: 50px;line-height: 50px;
        border-bottom: 1px dashed #ccc;
    }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .buttons {
        font-size: 0;
    }

    .button {
        margin: 5px 0 5px 5px;
        width: 100px;
        height: 30px;
        border-radius: 3px;
        border: none;
        background: #32D3A6;
        color: #FFFFFF;
    }

    .main {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    }
    #domId {
        flex: 1;
        height:500px;
    }
</style>